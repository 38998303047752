import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    turboFrameId: String
  }

  connect() {
    this.scrollTarget = document.getElementById(this.turboFrameIdValue);
  }

  onPageChange(event) {
    if (this.scrollTarget) {
      const yOffset = -100; // Offset fixed navbar
      const y = this.scrollTarget.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({
        top: y,
        behavior: "smooth"
      });
    }
  }
}