import dayjs from "dayjs";
import rails_timezone from "rails-timezone";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advancedFormat = require("dayjs/plugin/advancedFormat");
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

jQuery.fn.populateTimeZoneSelect = function () {
  var $el = $(this[0]);
  var $optionToSelect = null;

  // We only want to run this function if the element has the default UTC value
  if ($el.val() != "UTC") return;

  // Create a regular expression to match the local timezone
  var timeZone = rails_timezone.to(dayjs.tz.guess());
  var regEx = timeZone === undefined ? null : new RegExp(timeZone);

  // If we can't find an exact match, pick a time zone with the same GMT offset
  var offset = new Date().toString().match(new RegExp(/GMT([+-]\d{2})(\d{2})/));
  var gmtRegex = new RegExp("\\" + offset[1] + ":" + offset[2]);

  // Loop through all time zone options in the select element
  $("option", $el).each(function (index, option) {
    var $option = $(option);
    var optionHTML = $option.html();

    // Find the option that matches the local time zone
    if (regEx != null && optionHTML.match(regEx)) {
      $optionToSelect = $option;
      return false;
    } else if (optionHTML.match(gmtRegex)) {
      $optionToSelect = $option;
    }
  });

  if ($optionToSelect != null) $optionToSelect.prop("selected", true);
};

// Expects an element with a standard date string in ISO8601 format
// as its text value/ and the format specified via the 'data-format'
// attribute attribute
jQuery.fn.utcToLocalTime = function () {
  $(this).each(function (index, value) {
    var $el = $(value);
    var localTime = dayjs.tz(new Date($el.text()));
    $el.text(localTime.format($el.data("format")));
    $el.removeClass("time_utc");
  });
};
