// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "replyform", "editform", "replybutton", "kebabmenu", "sortform" ]

  // connect() {
  //   this.outputTarget.textContent = 'Hello, Stimulus!'
  // }

  toggle_reply(event) {
  	event.preventDefault()
  	this.replyformTarget.classList.toggle("d-none")
  }

  toggle_edit(event) {
  	event.preventDefault()
  	this.editformTarget.classList.toggle("d-none")
  }

  toggle_kebab_reply(event) {
    event.preventDefault()
    this.kebabmenuTarget.classList.toggle("d-none")
    this.replyformTarget.classList.toggle("d-none")
  }

  toggle_kebab_edit(event) {
    event.preventDefault()
    this.kebabmenuTarget.classList.toggle("d-none")
    this.editformTarget.classList.toggle("d-none")
  }

  toggle_kebab(event) {
    event.preventDefault()
    this.kebabmenuTarget.classList.toggle("d-none")
  }

  submit_sortform(event) {
    this.sortformTarget.submit()
  }
}
