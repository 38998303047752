import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["template", "fields", "search"]

  add_new_field(event) {
    event.preventDefault()
    let identifier = event.currentTarget.value

    this.fieldsTarget.innerHTML = '';
  
    this.templateTargets.forEach(target => {
      let content = target.innerHTML.replace(/values/g, target.dataset.models);
      if(target.dataset.id === identifier){
        this.fieldsTarget.insertAdjacentHTML('beforeend', content)
      }
    });
  }
  set_model_for_search(event){
    let options = event.currentTarget.selectedOptions;
    let values = Array.from(options).map(({ value }) => value);

    this.searchTargets.forEach(target => {
      target.dataset.models = values
      target.dataset.tomSelectModelsValue = values
    });
  }

  set_sorting_criteria_options(event){
    let options = event.currentTarget.selectedOptions;
    let index = event.currentTarget.dataset.index;
    let values = Array.from(options).map(({ value }) => value);

    get(`/admin/sorting_criterias?models=${values}&index=${index}`, {
      responseKind: "turbo-stream"
    });
  }
}
