import { Controller } from 'stimulus';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'
import Rails from "@rails/ujs"

export default class extends Controller {

  static targets = ['container', 'trigger', 'errorMessage', 'mediaItems'];
  static values = { fieldName: String, required: Boolean, ownerType: String, ownerId: Number, maxImages: Number }

  connect() {
    this.setupUppy(this.containerTarget);
  }

  setupUppy(container) {
    let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content"); 
    this.triggerTarget.addEventListener("click", this.trigger.bind(this));
  
    this.uppy = new Uppy({
      autoProceed: true,
      allowMultipleUploadBatches: true,
      restrictions: {
        minNumberOfFiles: this.minNewFiles,
        maxNumberOfFiles: this.maxNewFiles,
        allowedFileTypes: ["image/bmp", "image/png", "image/jpg", "image/jpeg"],
        maxFileSize: 10 * 1024 * 1024
      }
    })
  
    this.uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url
    })
  
    this.uppy.use(Dashboard, {
      closeAfterFinish: true,
      autoOpenFileEditor: false,
      hideUploadButton: true,
      showProgressDetails: true
    })
  
    this.uppy.on('complete', (result) => {
      container.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element));

      let data = new FormData();
      data.append("blob_ids", JSON.stringify(result.successful.map((x) => x.response.id)));
      data.append("owner_type", this.ownerTypeValue);
      data.append("owner_id", this.ownerIdValue);

      Rails.ajax({
        url: `/new_image_media_items`,
        type: "post",
        data: data,
        error: (e) => { console.log(e.errors); }
      });

      this.uppy.getState().files = {};
      this.uppy.getState().totalProgress = null;
    })

    this.mediaItemsObserver = new MutationObserver( this.mediaItemsChanged.bind(this) );
    this.mediaItemsObserver.observe(this.mediaItemsTarget, { attributes: true, childList: true, subtree: true } );
    this.mediaItemsChanged();
  }

  trigger(event) {
    event.preventDefault();
    if(this.minNewFiles > 0 && this.maxNewFiles > 0) {
      this.uppy.getPlugin('Dashboard').openModal();
    }
    else {
      alert("Please delete some images before uploading more!");
    }
  }

  mediaItemsChanged() {
    let mediaItemsCount = this.mediaItemsTarget.childElementCount;
    this.minNewFiles = (mediaItemsCount >= this.maxImagesValue) ? 0 : 1;
    this.maxNewFiles = Math.max(this.maxImagesValue - mediaItemsCount, 0);

    if(this.uppy) {
      this.uppy.setOptions({
        note: note,
        restrictions: {
          minNumberOfFiles: this.minNewFiles,
          maxNumberOfFiles: this.maxNewFiles,
          allowedFileTypes: ["image/bmp", "image/png", "image/jpg", "image/jpeg"]
        }
      });

      let note = (this.maxNewFiles > 1) ? `Add up to ${this.maxNewFiles} images` : "Add one more image";
      this.uppy.getPlugin('Dashboard').setOptions({ note: note });

      let label = this.maxNewFiles > 1 ? `Upload 1-${this.maxNewFiles} images` : "Upload 1 image";
      this.triggerTarget.getElementsByTagName('span')[0].textContent = label;
    }

    if(this.minNewFiles == 0 || this.maxNewFiles == 0) {
      this.containerTarget.classList.add('d-none');
    }
    else {
      this.containerTarget.classList.remove('d-none');
    }
  }
  
  appendUploadedFile(element, file, fieldName) {
    const hiddenField = document.createElement('input')
  
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', fieldName);
    hiddenField.setAttribute('data-pending-upload', true);
    hiddenField.setAttribute('value', file.response.signed_id);
  
    element.appendChild(hiddenField);
  }

}