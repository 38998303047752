import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["input", "embed", "image", "media"]
  static values = {
    index: String,
    key: String,
  }

  create_embed(event){
    let value = this.inputTarget.value;
    get(`/admin/embeds?link=${encodeURIComponent(value)}&index=${this.indexValue}&key=${this.keyValue}`, {
      responseKind: "turbo-stream"
    });
  }

  toggle_fields(event){
    let selectedMedia = event.currentTarget.selectedOptions[0].label
    this.mediaTargets.forEach(target => {
      target.classList.add('d-none')
    });
    if( selectedMedia == "image" ){
      this.imageTarget.classList.remove('d-none')
    }
    if( selectedMedia == "embed" ){
      this.embedTarget.classList.remove('d-none')
    }
  }
}