import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["template", "fields", "links", "removeField"]

  add_new_field(event) {
    event.preventDefault()
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }
}
