import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['input', 'characterCounter', 'errorMessage'];
  static values = { minLength: Number, maxLength: Number }

  connect() {
    this.inputTarget.addEventListener("input", function() { this.onInput() }.bind(this));
    this.inputTarget.addEventListener("focusout", function() { this.validate() }.bind(this));

    this.onInput();
    this.invalid = false;
  }

  onInput() {
    this.numOfEnteredChars = this.inputTarget.value.length;
    this.characterCounterTarget.textContent = this.numOfEnteredChars + "/" + this.maxLengthValue;

    if(this.invalid) {
      this.validate();
    }
  };

  validate() {
    let tooShort = this.numOfEnteredChars < this.minLengthValue;
    let tooLong = this.maxLengthValue >= 0 && (this.numOfEnteredChars > this.maxLengthValue);

    if(tooShort || tooLong) {
      this.inputTarget.classList.add("is-invalid");
      this.characterCounterTarget.classList.add("is-invalid");
      this.errorMessageTarget.classList.remove("d-none");
      this.invalid = true;
    } 
    else {
      this.inputTarget.classList.remove("is-invalid");
      this.characterCounterTarget.classList.remove("is-invalid");
      this.errorMessageTarget.classList.add("d-none");
      this.invalid = false;
    }
  }

}