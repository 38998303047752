import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Sortable from "sortablejs"

export default class extends Controller {
  // static targets = ["draggable"]
  static classes = ["active"]

  connect() {
    this.sortable = Sortable.create(this.element, {
      onStart: this.start.bind(this),
      onEnd: this.end.bind(this),
      handle: ".sortable-handle", // Only perform sort when dragging particular area
    })
  }

  start(event){
  }

  end(event) {
    const id = event.item.dataset.id
    if(id) {
      const type = event.item.dataset.type;
      let data = new FormData()
      data.append("position", event.newIndex + 1)
      data.append("type", type)
  
      Rails.ajax({
        url: `/admin/sortings/${id}`,
        type: 'PATCH',
        data: data
      })
    }
  }
}
