import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "main" ]
  static values = {
    images: Array,
    interval: Number,
    fadeTime: Number
  }

  connect() {
    this._i = 0;
    this.setUpdateTimeout();
  }

  setUpdateTimeout() {
    setTimeout(() => { this.updateImage(this) }, this.intervalValue);
  }

  updateImage(context) {
    var prevIndex = this._i;

    if(this._i == (this.imagesValue.length - 1)){
      this._i = 0; 
    } else {
      this._i++;
    }
    
    var mainTarget = $(this.mainTarget);
    var image = this.imagesValue[this._i]
    var fadeTime = this.fadeTimeValue;
    var newIndex = this._i;

    mainTarget.fadeOut(fadeTime, function () {
      mainTarget.css('background-image', 'linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.8)), url(' + image +')');
      mainTarget.fadeIn(fadeTime);

      $(`#hero-caption-${prevIndex}`).fadeOut(0)
      $(`#hero-caption-${newIndex}`).fadeIn(fadeTime, function() { context.setUpdateTimeout(); });
    });
  }
}
