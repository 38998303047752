import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    //Called when there's incoming data on the websocket for this channel
    //Add blue dot to bell-icon
    $('.bell-icon').addClass('unread-notifs')
    //Remove up to date message if any
    $('.no-notifs-message').remove();
    //Remove 5th notif
    $('.notifications_panel_body_inner a:nth-child(5)').remove();
    //Insert new notification into panel
    $(".notifications_panel_body_inner").prepend(data.html);
    }
});
