import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toToggle"]
  static classes = ["active"]
  static values = { togglersToClose: Array }

  toggle(event) {
    event.preventDefault();
    const targets = this.toToggleTargets;

    // Toggle active class on all specified targets
    targets.forEach(element => {
      element.classList.toggle(this.activeClass)
    });

    // Close any other togglers, if requested
    const togglersToClose = this.togglersToCloseValue.toString();
    $(togglersToClose).removeClass(this.activeClass);
  }
}