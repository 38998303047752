import { Controller } from 'stimulus';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import ImageEditor from '@uppy/image-editor';
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload'

export default class extends Controller {

  static targets = ['container', 'trigger', 'errorMessage'];
  static values = { fieldName: String, required: Boolean }

  connect() {
    this.setupUppy(this.containerTarget);
  }

  setupUppy(container) {
    let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content"); 
    this.triggerTarget.addEventListener("click", (event) => event.preventDefault());
  
    let uppy = new Uppy({
      autoProceed: false,
      allowMultipleUploadBatches: true,
      restrictions: {
        minNumberOfFiles: 1,
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/bmp", "image/png", "image/jpg", "image/jpeg"]
      }
    })
  
    uppy.use(ActiveStorageUpload, {
      directUploadUrl: direct_upload_url
    })
  
    uppy.use(Dashboard, {
      trigger: this.triggerTarget,
      closeAfterFinish: true,
      autoOpenFileEditor: true,
      hideUploadButton: true,
      locale: {
        strings: {
          xFilesSelected: {
            0: 'Crop your image before uploading',
            1: 'Crop your image before uploading',
          },
          editing: 'Crop and save your image'
        },
      }
    })

    uppy.use(ImageEditor, { 
      target: Dashboard,
      actions: {
        revert: false,
        rotate: false,
        granularRotate: false,
        flip: false,
        zoomIn: true,
        zoomOut: true,
        cropSquare: false,
        cropWidescreen: false,
        cropWidescreenVertical: false
      },
      cropperOptions: {
        aspectRatio: 1.91082802548
      }
    });
  
    uppy.on('complete', (result) => {
      container.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

      let file = result.successful.length ? result.successful[0] : null;
      if(file) {
        this.appendUploadedFile(container, file, this.fieldNameValue);
        this.setPreview(container, file);
        this.validate(true);
      }
      else {
        this.validate(false);
      }
    })

    uppy.on('file-editor:complete', (updatedFile) => {
      uppy.upload();
   })
  }
  
  appendUploadedFile(element, file, fieldName) {
    const hiddenField = document.createElement('input')
  
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', fieldName);
    hiddenField.setAttribute('data-pending-upload', true);
    hiddenField.setAttribute('value', file.response.signed_id);
  
    element.appendChild(hiddenField)
  }
  
  setPreview(element, file) {
    let preview = element.querySelector('[data-behavior="uppy-preview"]')
    if (preview) {
      let src = (file.preview) ? file.preview : "/assets/def_summary_image.png"
      preview.src = src
    }
  }

  validate(valid) {
    if (this.requiredValue) {
      if (valid) {
        this.errorMessageTarget.classList.add("d-none");
        this.containerTarget.classList.remove("is-invalid");
      } else {
        this.errorMessageTarget.classList.remove("d-none");
        this.containerTarget.classList.add("is-invalid");
      }
    }
  }

}