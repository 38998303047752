import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "form", "viewcontrols", "sortcontrols", "sortmenu", "gridview", "mapview", "galleryview"]
  static values = { defaultView: String }

  connect() {
    document.addEventListener("turbo:frame-load", function (e) {
      if(e.target.id == this.data.get("turbo-frame-id")) {
        this.init();
      }
    }.bind(this));
    this.init();
  }

  init() {
    if(this.showingMap || (this.showingMap == null && this.defaultViewValue == "map")) {
      this.showMap();
    } 
    else if(this.showingGallery || (this.showingGallery == null && this.defaultViewValue == "gallery")) {
      this.showGallery();
    }
    else {
      this.showGrid();
    }
  }

  showMap() {
    this.showingMap = true;
    this.showingGallery = false;

    if(this.hasGridviewTarget) {
      this.gridviewTarget.classList.add("hidden");
    }
    if(this.hasMapviewTarget) {
      this.mapviewTarget.classList.remove("hidden");
    }
    if(this.hasGalleryviewTarget) {
      this.galleryviewTarget.classList.add("hidden");
    }
    if(this.hasSortcontrolsTarget) {
      this.sortcontrolsTarget.classList.add("hidden");
    }
  }

  showGrid() {
    this.showingMap = false;
    this.showingGallery = false;

    if(this.hasGridviewTarget) {
      this.gridviewTarget.classList.remove("hidden");
    }
    if(this.hasMapviewTarget) {
      this.mapviewTarget.classList.add("hidden");
    }
    if(this.hasGalleryviewTarget) {
      this.galleryviewTarget.classList.add("hidden");
    }
    if(this.hasSortcontrolsTarget) {
      this.sortcontrolsTarget.classList.remove("hidden");
    }
  }

  showGallery() {
    this.showingMap = false;
    this.showingGallery = true;
    
    if(this.hasGridviewTarget) {
      this.galleryviewTarget.classList.remove("hidden");
    }
    if(this.hasMapviewTarget) {
      this.mapviewTarget.classList.add("hidden");
    }
    if(this.hasGridviewTarget) {
      this.gridviewTarget.classList.add("hidden");
    }
    if(this.hasSortcontrolsTarget) {
      this.sortcontrolsTarget.classList.remove("hidden");
    }
  }

  toggle_sortmenu(event) {
    event.preventDefault();
    this.sortmenuTarget.classList.toggle("d-none");
  }

  outside_toggle_sortmenu(event) {
    if(this.element === event.target || this.element.contains(event.target)) return;
    this.sortmenuTarget.classList.add("d-none");
  }

  onPageChange(event) {
    if (event.currentTarget.dataset.autoscroll == "true") {
      const yOffset = -100; // Offset fixed navbar
      const y = this.containerTarget.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({
        top: y,
        behavior: "smooth"
      });
    }
  }

  applyFilters(event) {
    $("#filters-modal").modal("hide");
  }

  resetFilters(event) {
    $('input[type=checkbox]').prop('checked', false);
    $("#filters-modal").modal("hide");

    event.preventDefault();
    this.submitForm();
  }

  removeFilter(event){
    var checkbox = $(event.srcElement.dataset.checkboxId);
    checkbox.prop('checked', false);

    event.preventDefault();
    this.submitForm();
  }

  submitForm() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 300)
  }
}