import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["field"]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      trigger: '#',
      allowSpaces: false,
      spaceSelectsMatch: true,
      lookup: 'name',
      menuItemTemplate: function (item) {
        if (item.original.id === undefined) {
          return `${item.string} (new tag)`
        } else {
          return item.string;
        }
      },
      values: this.fetchHashtags,
      noMatchTemplate: function () {
        return '<li><span class="no-match"><i>Invalid hashtag - try removing special characters ike</i></span></li>';
      }
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced.bind(this))
  }

  fetchHashtags(text, callback) {
    let originalText = text;
    fetch(`/hashtags.json?query=${text}`)
      .then(response => response.json())
      .then((hashtags) => {
        // Add an option for the user to create a new tag (if it doesn't already exist)
        if (originalText && !hashtags.some(h => h.name === originalText)) {
          hashtags.unshift({ id: undefined, name: `${originalText}`, sgid: undefined, content: undefined })
        }
        callback(hashtags)
      })
      .catch(error => callback([]))
  }

  replaced(e) {
    if (e.detail.instance.trigger === "#") {
      if (e.detail.item.original.id === undefined || e.detail.item === undefined) {
        Rails.ajax({
          url: `/hashtags`,
          type: "post",
          data: new URLSearchParams({ name: e.detail.context.mentionText }).toString(),
          error: (e) => {
            this.appendError(e.errors)
          },
          success: (data) => {
            let hashtag = data
            this.createNewTrixAttachment(hashtag)
          },
        });
      } else {
        let hashtag = e.detail.item.original
        this.createNewTrixAttachment(hashtag)
      }
    }
  }

  createNewTrixAttachment(hashtag) {
    let attachment = new Trix.Attachment({
      sgid: hashtag.sgid,
      content: hashtag.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - endPos + startPos, position + 1]);
    this.editor.deleteInDirection("backward")
  }

  appendError(error) {
    let msg = `<small id="hashtag-error"class="text-danger">${error}</small> `
    this.editor.element.insertAdjacentHTML('afterend', msg);
    let errorDiv = document.getElementById("hashtag-error");
    setTimeout(() => errorDiv.remove(), 3000);
  }
}
