import { Controller } from "stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["input"]
  static values = {
    create: Boolean,
    hideSelected: Boolean,
    maxItems: Number,
    maxOptions: Number,
    placeholder: String,
    plugins: Array,
    valueField: String,
    labelField: String,
    searchFields: Array,
    baseUrl: String,
    models: String,
  }

  connect() {
    new TomSelect(this.selectable, {
      create: this.hasCreateValue ? this.createValue : true,
      persist: false,
      maxItems: this.hasMaxItemsValue ? this.maxItemsValue : null,
      maxOptions: this.hasMaxOptionsValue ? this.maxOptionsValue : null,
      hideSelected: this.hasHideSelectedValue ? this.hideSelectedValue : true,
      placeholder: this.placeholder,
      plugins: this.pluginsValue,
      hidePlaceholder: true,
      copyClassesToDropdown: false,
      loadThrottle: 300,
      onItemAdd: (_value, _item) => { this.clearInputControl() },
      ...(this.hasValueFieldValue && { valueField: this.valueFieldValue }),
      ...(this.hasLabelFieldValue && { labelField: this.labelFieldValue }),
      ...(this.hasSearchFieldsValue && { searchField: this.searchFieldsValue }),
      ...(this.hasBaseUrlValue && { load: (query, callback) => { this.getOptions(query, callback) } }),
      ...(this.hasBaseUrlValue && { render: { option: this.render_option, item: this.render_item } }),
    });
  }

  // private
  getOptions(query, callback) {
    var url = this.baseUrlValue.replace(/value/g, encodeURIComponent(query)).replace(/model/g, this.modelsValue);
    fetch(url)
      .then(response => response.json())
      .then(json => {
        callback(json);
      }).catch(() => { callback(); });
  }

  render_option(item, escape) {
    return `<div>${escape(item.label)}</div`
  }

  render_item(item, escape) {
    return `<div>${escape(item.name)}</div`
  }

  clearInputControl() {
    const modifiedId = `${this.selectable.id}-ts-control`;
    const inputControl = document.getElementById(modifiedId);
    if (inputControl) { inputControl.value = ""; }
  }

  get selectable() {
    return this.hasInputTarget ? this.inputTarget : this.element
  }

  get placeholder() {
    if (this.hasPlaceholderValue) {
      return this.placeholderValue;
    } else {
      const placeholderText = this.selectable.getAttribute('placeholder');
      return placeholderText ? placeholderText : "Select ...";
    }
  }
}