import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['sidebar', 'unsavedChangesPrompt', 'sidebarWrapper', 'progressMarker'];
  static values = { published: Boolean, finalStep: Boolean, currentStepIndex: Number }

  connect() {

    /* The main form is actually our parent so that it can be used by
    each wizard step to define its own form elements. As such, we need
    to grab the element from the DOM manually, and can't rely on targets */
    this.form = document.querySelector('#wizard');
    this.dirtyForm = false;

    this.form.onsubmit = this.onFormSubmit;

    // Setup form change event listeners for all input fields
    this.formInputs = this.form.querySelectorAll('input, trix-editor');
    this.boundOnFormChanged = this.onFormChanged.bind(this);
    for (var i = 0; i < this.formInputs.length; i++)
    {
        var input = this.formInputs[i];
        input.addEventListener('input', this.boundOnFormChanged);
    }

    if (this.finalStepValue) {
      this.publishButton = document.getElementById('publish_button');
      this.publishCheckbox = document.querySelector('[id$="published"]');
      this.updatePublishButtonLabel();
    }

    // Ensure collapseable mobile header is in correct state based on device size
    window.addEventListener("resize", this.onWindowResize.bind(this));
    this.onWindowResize();

    // Position progress marker based on current step
    this.progressMarkerTarget.style.top = `${this.currentStepIndexValue * 34}px`;
  }

  onFormNavigationLink(event) {
    if(this.dirtyForm) {
      event.preventDefault();

      // Show confirmation popup and set the continue button
      // to point to wherever the original link pointed
      var targetAnchor = event.target.closest("a");
      var discardButton = document.querySelector('#discard-continue-button');
      discardButton.href = targetAnchor.href;
      discardButton.target = targetAnchor.target;
      $('#save-or-discard-modal').modal();
    }
  }

  onFormChanged() {
    this.setFormDirty(true);

    if (this.finalStepValue) {
      this.updatePublishButtonLabel();
    }
  }

  setFormDirty(dirty) {
    this.dirtyForm = dirty;
    if (dirty) {
      this.unsavedChangesPromptTarget.classList.remove('d-none');
    }
    else {
      this.unsavedChangesPromptTarget.classList.add('d-none');
    }
  }

  updatePublishButtonLabel() {
    let publishButtonLabel = "";

    if (this.publishedValue) {
      publishButtonLabel = this.publishCheckbox.checked ? "Save & Publish" : "Unpublish"
    } else {
      publishButtonLabel = this.publishCheckbox.checked ? "Save & Publish" : "Save Draft"
    }

    this.publishButton.textContent = publishButtonLabel;
    this.publishButton.value = publishButtonLabel;
  }

  onWindowResize() {
    var screenWidth = window.innerWidth;
    if(screenWidth >= 768) {
      $(this.sidebarWrapperTarget).collapse('show');
    }
    else {
      $(this.sidebarWrapperTarget).collapse('hide');
    }
  }

  onSaveAndPublishClicked(event) {
    event.preventDefault();

    let formEl = document.getElementById("wizard");
    if (!formEl.checkValidity() || document.querySelector('.is-invalid') != null) {
      formEl.reportValidity()
    }
    else {
      $("#save-and-publish-modal").modal();
    }
  }

  onFormSubmit() {
    $("#save-and-publish-modal").modal("hide");
    $('#save-or-discard-modal').modal("hide");
  }

  disconnect() {
    if (this.formInputs) {
      for (var i = 0; i < this.formInputs.length; i++)
      {
          var input = this.formInputs[i];
          input.removeEventListener('input', this.boundOnFormChanged);
      }
    }
    document.removeEventListener("trix-change", this.boundOnFormChanged);
  }

}