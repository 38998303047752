import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['form', 'directUploadContainer', 'fileInputContainer'];

  connect() {
    document.addEventListener("direct-upload:initialize", this.onInitialize.bind(this));
    document.addEventListener("direct-upload:start", this.onStart.bind(this));
    document.addEventListener("direct-upload:progress", this.onProgress.bind(this));
    document.addEventListener("direct-upload:error", this.onError.bind(this));
    document.addEventListener("direct-upload:end", this.onEnd.bind(this));
  }

  onInitialize(event) {
    const { id, file } = event.detail;

    // Hide form input and show progress indicators
    this.fileInputContainerTarget.classList.add("d-none");
    this.directUploadContainerTarget.classList.remove("d-none");

    // Append progress indicators per upload
    this.directUploadContainerTarget.insertAdjacentHTML("beforeend", `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <label id="direct-upload-label-${id}" class="direct-upload__filename">${file.name}</label>
      </div>
    `);
  }

  onStart(event) {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove("direct-upload--pending");
  }

  onProgress(event) {
    const { id, progress } = event.detail;
    const progressElement = document.getElementById(`direct-upload-progress-${id}`);
    progressElement.style.width = `${progress}%`;
  }

  onError(event) {
    event.preventDefault();
    const { id, error } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add("direct-upload--error");
    element.setAttribute("title", error);
  }

  onEnd(event) {
    const { id, file } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    const label = document.getElementById(`direct-upload-label-${id}`);
    element.classList.add("direct-upload--complete");
    label.textContent = `${file.name} ✅`;
  }

  disconnect() {
    document.removeEventListener("direct-upload:initialize", this.onInitialize.bind(this));
    document.removeEventListener("direct-upload:start", this.onStart.bind(this));
    document.removeEventListener("direct-upload:progress", this.onProgress.bind(this));
    document.removeEventListener("direct-upload:error", this.onError.bind(this));
    document.removeEventListener("direct-upload:end", this.onEnd.bind(this));
  }

}