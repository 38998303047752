import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'frame', 'heading', 'loader', 'turboWrapper'];
  static values = {
    local: Boolean
  }

  connect() {

    // Only non-local modals (i.e. those that can display dynamic content
    // from the server) need to check for mutations and send events
    if (!this.localValue) {
      this.observer = new MutationObserver(this.frameMutated.bind(this))
      this.observer.observe(this.frameTarget, { attributes: true, childList: true, characterData: false })
      this.modalUpdatedEvent = new Event("modal_updated");
    }
  }

  disconnect() {
    if (!this.localValue) {
      this.observer.disconnect()
      delete this.observer
    }
  }

  frameMutated (mutations) {
    // Show the modal as soon as the frame starts updating
    // and show a loader while we wait for the final content
    if (this.frameTarget.hasAttribute('busy')) 
    {      
      this.turboWrapperTarget.classList.add('hidden');
      this.loaderTarget.classList.remove('hidden');
      this.headingTarget.innerHTML = "Loading";
      $(this.containerTarget).modal();
    }

    // When the final content is ready, reveal the new content
    // and hide the loader
    if (mutations[0] && mutations[0].type == "childList") {
      // Pull title out of hidden element within Turbo frame
      var titleEl = this.containerTarget.querySelector('#titleContent');
      this.headingTarget.innerHTML = titleEl.innerHTML;

      this.loaderTarget.classList.add('hidden');
      this.turboWrapperTarget.classList.remove('hidden');
      document.dispatchEvent(this.modalUpdatedEvent);
    }
  }
}
