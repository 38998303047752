import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]
  static values = {
    id: String,
    url: String
  }

  remove_inputs() {
    this.inputTargets.forEach(input => {
      input.remove();
      if(this.hasIdValue && this.idValue !== ""){
        $.ajax({ 
          url: this.urlValue + this.idValue,
          type: "delete",
        });  
      }
    })
  }
}
