require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("awesomplete/awesomplete");
require("@fortawesome/fontawesome-free/js/all");
require("bootstrap/dist/js/bootstrap");
require("custom/custom");
require("trix");
require("@rails/actiontext");
require('@uppy/core/dist/style.min.css')
require('@uppy/dashboard/dist/style.min.css')
require('@uppy/image-editor/dist/style.min.css');

import "@hotwired/turbo-rails";
import "../stylesheets/application";
import "controllers";
import "trix/trix-extensions";
import 'tom-select'

// Enable use of jQuery in views e.g. js.erb files
import $ from "jquery";
global.$ = $;
