import Rails from "@rails/ujs";

document.addEventListener("turbo:load", () => {
  $(".toast").toast("show");

  $(".alert-success.fade").delay(3000).fadeOut(5000);

  $("a#reply_link").click(function (event) {
    event.preventDefault();
    $("div#whatever").toggle();
  });

  $(".form__inner-button").click(function () {
    this.closest("form").submit();
  });

  $("[data-thirdpartysource]").click(function () {
    addThirdPartyConsent(this.dataset.thirdpartysource);
  });

  $(".form__input__chat").on("input", function () {
    this.style.height = "auto";
    this.style.height = this.scrollHeight + "px";
  });

  $('#image-input').on('change', function(event) {
    var imageFile = event.target.files[0];
    var fileReader = new FileReader();
    fileReader.onload = function(file) {
      var image = new Image();
      image.src = file.target.result;

      var previewTarget = $('.image-preview-target');
      if(previewTarget.data('width') && previewTarget.data('height')) {
        image.width = parseFloat(previewTarget.data('width'));
        image.height = parseFloat(previewTarget.data('height'));
        image.className = previewTarget.data('class');
        previewTarget.html(image);
      } else {
        previewTarget.removeClass('hidden');
        previewTarget.css('background-image', 'url(' + file.target.result + ')');
      }      
    }
    fileReader.readAsDataURL(imageFile);
  });

  // Auto focus on relevant elements
  var focusOnLoad = $(".focus-on-load")[0];
  if(focusOnLoad != undefined)
  {
    window.scrollTo({
      top: focusOnLoad.getBoundingClientRect().top - 110, // Header bar offset
      behavior: "smooth"
    });
  }
});

function addThirdPartyConsent(value) {
  Rails.ajax({
    url: `/users/add_third_party_consent/${encodeURIComponent(value)}`,
    type: "PATCH",
    success: function (data) {
      location.reload();
    },
  });
}
