import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["medialinkinput", "addembedbutton"]

    connect() {      
      this.getThirdPartySources();
      this.initEventHandlers();
    }
  
    initEventHandlers() {
      this.medialinkinputTarget.addEventListener("input", this.handleInput.bind(this));
      this.medialinkinputTarget.addEventListener("focusin", this.handleInput.bind(this));
      this.addembedbuttonTarget.addEventListener("click", this.addEmbed.bind(this));
    }
  
    handleInput(event) {
      let value = event.target.value.trim();
      let matched = false;
  
      // Check the input against our list of embeddable sources
      this.thirdpartysources.forEach(source => {
        let matches = value.match(source.regex);
        if (matches != null) {
          this.enableEmbedOption(source.source, matches[1], matches[2]);
          matched = true;
          return;
        }
      });
  
      // No embed matched, just reset the form
      if (!matched) {
        this.reset(); 
      }
    }
  
    enableEmbedOption(source, url, params) {
      this.embed_source = source; 
      this.embed_url = url;
      this.embed_params = params;

      this.addembedbuttonTarget.classList.remove("d-none");
      this.addembedbuttonTarget.classList.add("d-flex");
    }
  
    getThirdPartySources() {
      $.ajax({ 
        url: `/third_party_sources`,
        type: "get",
        success: this.onGetThirdPartySources.bind(this)
      });
    }
  
    addEmbed(event) {
      if (this.embed_source == null || this.embed_url == null || event.srcElement.dataset.sectionId == null) {
        return;
      }
      this.createEmbed(this.embed_source, this.embed_url, this.embed_params, event.srcElement.dataset.sectionId);
    }

    createEmbed(source, url, params, mediaitemable_id) {
      $.ajax({ 
        url: `/add_page_embed`,
        type: "patch",
        data: { source: source, url: url, params: params, mediaitemable_id: mediaitemable_id },
        error: this.reset.bind(this),
        success: this.onCreateEmbedSuccess.bind(this),
      });
    }
  
    onCreateEmbedSuccess(embed) {

    }
  
    reset() {
      this.embed_url = null;
      this.embed_source = null;
      this.embed_params = null;

      this.addembedbuttonTarget.classList.add("d-none");
      this.addembedbuttonTarget.classList.remove("d-flex");
    }

    onGetThirdPartySources(sources) {
      this.thirdpartysources = sources;
  
      this.thirdpartysources.forEach(source => {
        source.regex = new RegExp(source.regex);
      });
    }
  }